import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import { postsSummariesState } from "../state/postState";
import { useParams } from "react-router";
import styled from "styled-components";
import { ContentCardIcons } from "../components/website/overview/contentCards/iconMappings";
import { convertPostToEvent } from "../components/post/convertPostToEvent";
import Card from "../components/common/Card";
import { InputTextarea } from "primereact/inputtextarea";
import { Avatar } from "primereact/avatar";
import { userState } from "../state/userState";
import { InputText } from "primereact/inputtext";
import Button from "../components/common/form/Button";

const PageWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 80%;
  max-width: 1000px;
  gap: 20px;
`;

const FeedWrapper = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    width: 1px;
    height: 100%;
    background: var(--border-color);
    right: 25%;
    z-index: -1;
  }
`;

const PostCard = styled(Card)`
  display: flex;
  flex-direction: row;
  padding: 20px;
  gap: 20px;
  flex: 1;
`;

const PostContentWrapper = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const TimeWrapper = styled.div`
  font-size: 0.875em;
  font-weight: 500;
  color: #606770;
`;

const PlatformsWrapper = styled.div`
  display: flex;
  gap: 8px;
  .icon {
    width: 20px;
    height: 20px;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  max-width: 500px;
  margin-top: 10px;
  img {
    width: 100%;
    max-height: 500px;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
`;

const PostText = styled.div`
  font-size: 1em;
  color: #1c1e21;
  margin-top: 15px;
  text-align: start;
  line-height: 1.5;
`;

const CommentSection = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const CommentInputWrapper = styled(Card)``;

const CommentInputCollapsed = styled(InputText)`
  width: 100%;
  border-radius: 8px;
  border: none;
  padding: 10px;
  font-size: 1em;
`;

const CommentInputExpanded = styled(InputTextarea)`
  width: 100%;
  border-radius: 8px;
  border: 1px solid #ddd;
  padding: 10px;
  margin-top: 10px;
  font-size: 1em;
`;

const PostButton = styled(Button)`
  margin-top: 10px;
  padding: 10px 20px;
  font-size: 12px;
  margin-right: auto;
`;

const AvatarStyled = styled(Avatar)`
  background-color: var(--yellow);
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 60px;
  gap: 20px;
  flex: 1;
`;

const SingleCommentWrapper = styled(Card)`
  display: flex;
  flex-direction: start;
  padding: 15px;
  margin-top: 20px;
  flex-direction: column;
  gap: 10px;
`;

const CommentUser = styled.div`
  font-size: 1em;
  font-weight: 500;
`;

const CommentDate = styled.div`
  font-size: 0.875em;
  color: #606770;
`;

const Comment = styled.div`
  font-size: 1em;
  color: #1c1e21;
  text-align: start;
  line-height: 1.5;
`;

const FeedPage: React.FC = () => {
  const { websiteId } = useParams();
  const posts = useRecoilValue(postsSummariesState(websiteId as string));
  const events = posts.map((post) => convertPostToEvent(post)).reverse();
  const [expandedCommentIndex, setExpandedCommentIndex] = useState<
    number | null
  >(null);

  const handleBlur = (index: number) => {
    if (expandedCommentIndex === index) {
      setExpandedCommentIndex(null);
    }
  };

  const user = useRecoilValue(userState);

  return (
    <PageWrapper>
      <ContentWrapper>
        <FeedWrapper>
          {events.map((event, index) => (
            <SectionWrapper key={index}>
              <PostCard>
                <PostContentWrapper>
                  <TopWrapper>
                    <TimeWrapper>
                      {event.date.toLocaleDateString()}{" "}
                      {event.date.toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: false,
                      })}
                    </TimeWrapper>{" "}
                    <PlatformsWrapper>
                      {event.platforms.map((p) => p)}
                    </PlatformsWrapper>
                  </TopWrapper>

                  <ImageWrapper>
                    <img
                      src={event.imageUrl ? event.imageUrl : ""}
                      alt="Event Image"
                    />
                  </ImageWrapper>

                  <PostText>{event.event}</PostText>
                </PostContentWrapper>
              </PostCard>
              <CommentSection>
                <CommentInputWrapper>
                  <div className="flex align-items-center">
                    <AvatarStyled
                      label={user?.email.at(0)?.toLocaleUpperCase()}
                      shape="circle"
                      className="ml-2"
                    />
                    <CommentInputCollapsed
                      placeholder="רשום תגובה..."
                      onFocus={() => setExpandedCommentIndex(index)}
                    />
                  </div>
                  {expandedCommentIndex === index ? (
                    <>
                      <PostButton bgColor="purple" primary>
                        הוסף תגובה
                      </PostButton>
                    </>
                  ) : (
                    <></>
                  )}
                  <SingleCommentWrapper>
                    <div className="flex align-items-center gap-10">
                      <AvatarStyled
                        label={user?.email.at(0)?.toLocaleUpperCase()}
                        shape="circle"
                        className=""
                      />
                      <CommentUser>shimi</CommentUser>
                      <CommentDate>24/1/24 15:32</CommentDate>
                    </div>
                    <Comment>אהבתי רצח נראה טוב הטמבל</Comment>
                  </SingleCommentWrapper>
                </CommentInputWrapper>
              </CommentSection>
            </SectionWrapper>
          ))}
        </FeedWrapper>
      </ContentWrapper>
    </PageWrapper>
  );
};

export default FeedPage;
