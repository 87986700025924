import styled from "styled-components";
import postService from "../../core/services/post.service";
import { PostEntity, PostStatusType } from "neword-core";
import { useRef, useState, useEffect } from "react";
import { Menu } from "primereact/menu";
import { toast } from "react-toastify";
import { Theme } from "../../core/theme/theme";
import { Tooltip } from "primereact/tooltip";
import { ReactComponent as ErrorIcon } from "../../assets/Icons/Error.svg";
import { ReactComponent as ApprovedIcon } from "../../assets/Icons/Approved.svg";
import { Divider } from "primereact/divider";
import { PostCardIcons } from "./postIconMappings";
import PublishPostModal from "./PublishPostModal";
import PreviewPostModal from "./PreviewPostModal";
import { postsSummariesState } from "../../state/postState";
import { useRecoilState } from "recoil";
import { useParams } from "react-router";
import { MenuItem } from "primereact/menuitem";

const Wrapper = styled.div`
  display: flex;
  position: relative;
  &:hover {
    .menu-container {
      pointer-events: all;
      opacity: 1 !important;
    }
  }

  .menu-container {
    transition-duration: 0.1s;
    pointer-events: none;
    opacity: 0;
    padding: 0 7px;
    position: absolute;
    z-index: 9;
    width: fit-content;
  }
`;
const MenuStyled = styled(Menu)`
  .p-menu {
  }
  .p-menuitem-icon {
    margin-right: 0px !important;
    margin-left: 10px;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  width: 100%;
`;

const PlatformsWrapper = styled.div`
  display: flex;
  gap: 5px;

  svg {
    width: 15px;
    height: 15px;
  }
  .icon {
    width: 10px;
    height: 10px;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  img {
    border-radius: 6px;
    width: 100%;
    height: 80px;
    object-fit: cover;
  }
`;

const PostText = styled.div`
  font-size: 12px;
  color: var(--text-color);
  margin-top: 10px;
  text-align: start;
`;

const Status = styled.span<{ draft?: boolean }>`
  color: ${(props) => (props.draft ? "var(--yellow)" : "var(--success)")};
  font-weight: bold;
  font-size: 0.8em;
`;

const TileWrapper = styled.div<{ imageUrl?: string }>`
  cursor: pointer;
  background: white;
  margin-top: 10px;
  border: solid 1px var(--border-color);
  color: ${Theme.colors.text};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: ${(props) => `url(${props.imageUrl})`};
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  font-family: "Open Sans", "open sans Hebrew";
  width: 100%; /* Allow the tile to take the full width */
  padding: 10px; /* Add some padding */
  box-sizing: border-box;
  position: relative;

  &:hover {
    border: solid 1px var(--primary-purple);

    .menu-container {
      pointer-events: all;
      opacity: 1;
    }
  }
`;

const IconWrapper = styled.div`
  width: 15px;
  height: 15px;

  svg {
    width: 15px;
    height: 15px;
  }
`;

const TimeWrapper = styled.div`
  font-size: 0.8em;
  font-weight: bold;
  color: var(--title-color);
`;

const PostCalendarEvent = ({ post }: { post: PostEntity }) => {
  const menuRef = useRef<Menu>(null);
  const tileRef = useRef<HTMLDivElement>(null);
  const { websiteId } = useParams();

  const [_, setPosts] = useRecoilState(
    postsSummariesState(websiteId as string)
  );
  const [showPreviewPostModal, setShowPreviewPostModal] = useState(false);
  const [isRightAligned, setIsRightAligned] = useState(false);

  useEffect(() => {
    const handlePositionCheck = () => {
      if (tileRef.current) {
        const rect = tileRef.current.getBoundingClientRect();
        console.log(rect.right);
        setIsRightAligned(window.innerWidth - rect.right < 400);
      }
    };

    handlePositionCheck();
    window.addEventListener("resize", handlePositionCheck);
    return () => {
      window.removeEventListener("resize", handlePositionCheck);
    };
  }, []);

  const menuItems: MenuItem[] = [
    {
      label: "אפשרויות מהירות",
      items: [
        {
          label: "עריכת פוסט",
          icon: "pi pi-pencil",
          command: () => {
            setShowPreviewPostModal(true);
          },
        },
        post.status !== PostStatusType.APPROVED
          ? {
              label: "אישור פוסט",
              icon: "pi pi-check",
              command: () => {
                postService
                  .updatePostStatus(post.id, PostStatusType.APPROVED)
                  .then((newPost) => {
                    setPosts((oldPosts) =>
                      oldPosts.map((p) => (p.id === post.id ? newPost : p))
                    );
                    toast.success("הפוסט אושר בהצלחה");
                  });
              },
            }
          : {
              label: "ביטול פוסט",
              icon: "pi pi-times",
              command: () => {
                postService
                  .updatePostStatus(post.id, PostStatusType.REJECTED)
                  .then((newPost) => {
                    setPosts((oldPosts) =>
                      oldPosts.map((p) => (p.id === post.id ? newPost : p))
                    );
                    toast.success("הפוסט בוטל בהצלחה");
                  });
              },
            },
        post.status !== PostStatusType.PUBLISHED
          ? {
              label: "פרסם עכשיו",
              icon: "pi pi-upload",
              command: () => {
                postService.publishPost(post.id).then((newPost) => {
                  setPosts((oldPosts) =>
                    oldPosts.map((p) => (p.id === post.id ? newPost : p))
                  );
                  toast.success("הפוסט פורסם בהצלחה");
                });
              },
            }
          : {
              label: "צפה בפוסט",
              icon: "pi pi-eye",
              command: () => {
                post.externalLink && window.open(post.externalLink, "_blank");
              },
            },
        {
          label: "הסתר מהלקוחות",
          icon: "pi pi-eye-slash",
        },
      ],
    },
  ];

  return (
    <Wrapper className="w-full realtive">
      <TileWrapper ref={tileRef} onClick={() => setShowPreviewPostModal(true)}>
        <TopWrapper className="mb-2">
          <div className="flex gap-10">
            <Tooltip target=".approved-icon" />
            {post.status === PostStatusType.APPROVED && (
              <IconWrapper
                className="approved-icon"
                data-pr-tooltip="הפוסט אושר"
                data-pr-position="top"
              >
                <ApprovedIcon />
              </IconWrapper>
            )}

            <Tooltip target=".error-icon" />
            {post.status !== PostStatusType.APPROVED && (
              <IconWrapper
                className="error-icon"
                data-pr-tooltip="על מנת לפרסם את הפוסט יש לאשר אותו תחילה"
                data-pr-position="top"
              >
                <ErrorIcon />
              </IconWrapper>
            )}
          </div>
          <div className="flex align-items-center gap-10">
            <TimeWrapper>
              {post.scheduleDate.getHours().toString().padStart(2, "0") +
                ":" +
                post.scheduleDate.getMinutes().toString().padStart(2, "0")}
            </TimeWrapper>
            <PlatformsWrapper>
              {[PostCardIcons[post.type].icon].map(
                (PlatformIcon, iconIndex) => PlatformIcon
              )}
            </PlatformsWrapper>
          </div>
        </TopWrapper>
        <ImageWrapper>
          {post.data.imageUrl && (
            <img src={post.data.imageUrl} alt="Event Image" />
          )}
        </ImageWrapper>
        <PostText>
          {post?.data && post.data.message.length > 46
            ? post.data.message.substring(0, 46) + "..."
            : post.data.message || ""}
        </PostText>
      </TileWrapper>
      <div
        className="menu-container"
        style={{
          left: isRightAligned ? "auto" : "100%",
          right: isRightAligned ? "100%" : "auto",
        }}
      >
        <MenuStyled model={menuItems} />
      </div>
      {showPreviewPostModal && (
        <PreviewPostModal
          post={post}
          onHide={() => setShowPreviewPostModal(false)}
        />
      )}
    </Wrapper>
  );
};

export default PostCalendarEvent;
