import { PostEntity } from "neword-core";
import { PostCardIcons } from "./postIconMappings";

export const convertPostToEvent = (post: PostEntity) => {
  switch (post.data.type) {
    case "FACEBOOK_POST":
      return {
        date: new Date(post.scheduleDate),
        event: post.data.message,
        imageUrl: post.data.imageUrl,
        platforms: [PostCardIcons[post.type].icon],
        id: post.id,
        status: post.status,
      };

    case "INSTAGRAM_POST":
      return {
        date: new Date(post.scheduleDate),
        event: post.data.message,
        imageUrl: post.data.imageUrl,
        platforms: [PostCardIcons.INSTAGRAM_POST.icon],
        id: post.id,
        status: post.status,
      };
    // case "TWITTER_TWEET":
    //   return {
    //     date: new Date(post.scheduleDate),
    //     event: "פרסום לטוויטר",
    //     imageUrl: null,
    //     platforms: [PostCardIcons.TWITTER_TWEET.icon],
    //   };
    case "TIKTOK_POST":
      return {
        date: new Date(post.scheduleDate),
        event: post.data.message,
        imageUrl: null,
        platforms: [PostCardIcons.TIKTOK_POST.icon],
        id: post.id,
        status: post.status,
      };
    case "LINKEDIN_POST":
      return {
        date: new Date(post.scheduleDate),
        event: post.data.message,
        imageUrl: post.data.imageUrl,
        platforms: [PostCardIcons.LINKEDIN_POST.icon],
        id: post.id,
        status: post.status,
      };
    default:
      return {
        date: new Date(post.scheduleDate),
        event: "פרסום לפייסבוק",
        imageUrl: null,
        platforms: [PostCardIcons[post.type].icon],
        id: post.id,
        status: post.status,
      };
  }
};
