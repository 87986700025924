import { PostEntityType } from "neword-core";
import { PostData } from "neword-core/dist/cjs/entities/post/postData";

export const createPostDeafultParams = (postType: PostEntityType): PostData => {
  switch (postType) {
    case PostEntityType.FACEBOOK_POST:
      return {
        type: "FACEBOOK_POST",
        message: "",
        published: false,
        imageUrl: "",
      };

    case PostEntityType.INSTAGRAM_POST:
      return {
        type: "INSTAGRAM_POST",
        media_type: "IMAGE",
        media_ids: [],
        message: "",
        imageUrl: "",
      };
    case PostEntityType.LINKEDIN_POST:
      return {
        type: "LINKEDIN_POST",
        message: "",
        imageUrl: "",
      };

    //    case PostEntityType.LINKEDIN_POST:
    //     return {
    //       type: "",
    //       media_type: "IMAGE",
    //       media_ids: [],
    //       message: "",
    //  };
    default:
      return {
        type: "FACEBOOK_POST",
        message: "",
        published: false,
      };
  }
};
