import { Divider } from "primereact/divider";
import { PostEntity, PostEntityType } from "neword-core";
import { InputTextarea } from "primereact/inputtextarea";
import { FieldValues, Path, useFormContext, useWatch } from "react-hook-form";
import styled from "styled-components";
import AttachmentInput from "../../common/form/AttachmentInput";
import FacebookPreview from "../../../assets/images/facebookPreview.png"; // Add Facebook preview image
import { useMemo, useEffect, useRef } from "react";
import FbPreview from "../postPreviews/fbPreview";
import CardTitle from "../../common/CardTitle";

const InputTextareaStyled = styled(InputTextarea)`
  outline: none !important;
  min-height: fit-content;
  height: auto;
  width: 100%;
  border: none;
  resize: none; /* Optional: prevent manual resizing */
  &::placeholder {
    color: var(--text-color);
    opacity: 0.5;
  }

  &:hover {
    background: var(--light-bg);
  }
  &:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none !important;
    background: var(--light-bg);
    border-color: none !important;
  }
`;

const LabelStyled = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: var(--text-color);
  padding-bottom: 10px;
`;

const ImageWrapper = styled.div`
  background: #fcf6f7;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
  }
`;

const FacebookPreviewWrapper = styled.img``;

function FacebookPostForm<FormDataType extends FieldValues>({
  name,
}: {
  name: Path<FormDataType>;
}) {
  const { control, getValues, register } = useFormContext();

  const values = useWatch({ control, name: name }); // Ensure posts has a default value of an empty array
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  // Memoize imageUrl to prevent unnecessary re-renders
  const imageUrl = useMemo(() => {
    return (values && values.imageUrl) || FacebookPreview;
  }, [values]);

  // Function to automatically adjust textarea height
  const autoResizeTextarea = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  // Run auto-resize on mount and when posts change
  useEffect(() => {
    autoResizeTextarea();
  }, [values]);

  return (
    <div className="grid h-full m-0">
      <div className="col-5 p-0">
        <ImageWrapper className="p-4">
          <FbPreview name={name} />
        </ImageWrapper>
      </div>
      <div className="col-7 p-0">
        <div className="media p-2 mt-4">
          <CardTitle title="פרסום פוסט באינסטגרם" />

          <LabelStyled>הוספת מדיה</LabelStyled>
          <AttachmentInput<any> name={`${name}.imageUrl`} />
        </div>

        <Divider />

        <div className="post p-2 mb-4">
          <LabelStyled>תוכן הפוסט</LabelStyled>
          <InputTextareaStyled
            {...register(`${name}.message`)}
            className="w-90 pl-3"
            placeholder="רשמו משהו, בלה בלה תיאור לפוסט"
            onInput={autoResizeTextarea}
            ref={(e) => {
              register(`${name}.message`).ref(e);
              textareaRef.current = e;
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default FacebookPostForm;
