import { ReactComponent as IgIcon } from "../../../../assets/Icons/InstagramIcon.svg";
import { ReactComponent as IgIconPlain } from "../../../../assets/Icons/PlainSocialIcons/Instagram.svg";
import { ReactComponent as Facebook } from "../../../../assets/Icons/facebook/FacebookLogo.svg";
import { ReactComponent as FacebookPlain } from "../../../../assets/Icons/PlainSocialIcons/Facebook.svg";
import { ReactComponent as Linkedin } from "../../../../assets/Icons/linkedin/LinkedInLogo.svg";
import { ReactComponent as LinkedinPlain } from "../../../../assets/Icons/PlainSocialIcons/Linkedin.svg";
import { ReactComponent as Twitter } from "../../../../assets/Icons/XLogo.svg";
import { ReactComponent as Threads } from "../../../../assets/Icons/ThreadsLogo.svg";
import { ReactComponent as Email } from "../../../../assets/Icons/EmailLogo.svg";
import { ReactComponent as Sms } from "../../../../assets/Icons/SmsIcon.svg";
import { ReactComponent as WebsiteLogo } from "../../../../assets/Icons/WebsiteLogo.svg";
import { ReactComponent as Google } from "../../../../assets/Icons/ColoredGoogle.svg";
import { ReactComponent as FacebookFlat } from "../../../../assets/Icons/FacebookIconFlat.svg";
import { ReactComponent as EmailFlat } from "../../../../assets/Icons/EmailIconFlat.svg";
import { ReactComponent as LinkedinFlat } from "../../../../assets/Icons/LinkedinIconFlat.svg";
import { ContentCardType } from "./cardTypes";
import styled from "styled-components";

const GoogleStyled = styled(Google)`
  width: 20px;
  height: 20px;
`;

// Define a type for the icons and their corresponding colors
type ContentCardIcon = {
  icon: React.ComponentType<any>;
  iconPlain?: React.ComponentType<any>;
  color: string;
  socialPostingText?: string;
};

// Map the types to their corresponding icon components and colors
export const ContentCardIcons: Record<ContentCardType, ContentCardIcon> = {
  [ContentCardType.INSTAGRAM]: {
    icon: IgIcon,
    iconPlain: IgIconPlain,
    color: "#C13584", // Instagram's official gradient pink color
    socialPostingText: "פרסם באינסטגרם",
  },
  [ContentCardType.FACEBOOK]: {
    icon: Facebook,
    iconPlain: FacebookPlain,
    color: "#1877F2", // Facebook's official blue color
    socialPostingText: "פרסם בפייסבוק",
  },
  [ContentCardType.TWITTER]: {
    icon: Twitter,
    color: "black", // Twitter's official blue color
  },
  [ContentCardType.LINKEDIN]: {
    icon: Linkedin,
    iconPlain: LinkedinPlain,
    color: "#0077B5", // LinkedIn's official blue color
    socialPostingText: "פרסם בלינקדאין",
  },
  [ContentCardType.THREADS]: {
    icon: Threads,
    color: "#000000", // Threads' official black color
  },
  [ContentCardType.EMAIL]: {
    icon: Email,
    color: "#D44638", // Gmail's official red color
    // iconPlain: EmailFlat,
  },
  [ContentCardType.SMS]: {
    icon: Sms,
    color: "#4CAF50", // SMS/Android Messages green color
  },
  [ContentCardType.WEB]: {
    icon: WebsiteLogo,
    color: "#F7931E", // Generic web orange color
  },
  [ContentCardType.GOOGLE]: {
    icon: GoogleStyled,
    color: "#4285F4", // Google blue color
  },
} as const;
