import React, { useCallback, useRef, useState } from "react";
import Calendar from "react-calendar";
import { TileContentFunc, Value } from "react-calendar/dist/cjs/shared/types";
import styled from "styled-components";
import { Theme } from "../core/theme/theme";

import ComingSoon from "../components/document/components/ComingSoon";
import { useRecoilState, useRecoilValue } from "recoil";
import { postsSummariesState } from "../state/postState";
import { useParams } from "react-router";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import PostCalendarEvent from "../components/post/PostCalendarEvent";
import postService from "../core/services/post.service";
import { PostEntity } from "neword-core";

const CreateDocument = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  padding: 10px 15px;
  border-radius: 8px;
  border: dashed 1px var(--border-color);
  opacity: 0;
  cursor: pointer;

  &:hover {
    border-color: var(--primary-purple);
    color: var(--primary-purple);
  }
`;

const CalendarStyled = styled(Calendar)`
  width: 100%;
  border: none;
  font-family: "Assistant";

  button.react-calendar__tile {
    overflow: visible !important;
  }

  .react-calendar__tile {
    min-height: 150px;
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    line-height: 16px;
    font: inherit;
    font-size: 0.833em;
    border: solid 0.5px var(--border-color);
    display: flex;
    font-family: "Assistant";
    flex-direction: column;

    &:hover {
      ${CreateDocument} {
        opacity: 1;
      }
    }

    abbr {
      text-decoration: none;
      float: right;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      font-family: "Assistant";
    }
  }

  .react-calendar__navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: row;
  }

  .react-calendar__navigation__label {
    background: none;
    border: none;
    flex-grow: 0 !important;
    padding: 0 20px;
    pointer-events: none;
    font-weight: bold;
    text-transform: uppercase;
    font-family: "Open Sans", "open sans Hebrew";
  }

  .react-calendar__navigation__arrow {
    background: none;
    border: none;
    font-size: 16px;
    padding: 0 10px;
    font-weight: bold;
    cursor: pointer;
  }

  .react-calendar__month-view__weekdays__weekday {
    /* background: var(--light-bg); */
    display: flex;
    justify-content: center;
    text-decoration: none !important;
    padding: 10px;
    border-bottom: ${Theme.colors.border};
    margin-top: 10px;
    abbr {
      text-decoration: none;
      text-transform: uppercase;
      font-family: "Open Sans", "open sans Hebrew";
      font-size: 12px;
      color: var(--text-color);
    }
  }

  .past-date {
    background-color: var(--light-bg); /* Styling for past dates */
  }

  abbr + div {
    width: 100%;
    height: 100%;
  }
  .react-calendar__tile--now abbr {
    background-color: var(--purple-opacity);
    color: var(--primary-purple);
    font-family: "Assistant";
    border: solid 1px var(--primary-purple);
  }
`;

const DraggablePost = ({ post }: { post: any }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "POST",
    item: { post },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div ref={drag} style={{ opacity: isDragging ? 0.5 : 1 }}>
      <PostCalendarEvent post={post} />
    </div>
  );
};
function DroppableTile({ date, children, onDropEvent }: any) {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: "POST",
    drop: (item: any) => onDropEvent(item.post, date),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  return (
    <div
      ref={drop}
      style={{
        backgroundColor: isOver ? "rgba(173, 216, 230, 0.5)" : "transparent", // Lighter blue and fully covers tile
        minHeight: "150px", // Ensure min height
        padding: "0px",
        boxSizing: "border-box", // Ensure padding does not cause overflow
        border: isOver ? "2px dashed #00aaff" : "none", // Dashed border when dragging over
        width: "100%", // Ensure full width
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start", // Center content vertically
        alignItems: "center", // Center content horizontally
        transition: "background-color 0.2s ease", // Smooth transition for hover
      }}
    >
      {children}
    </div>
  );
}
function MyCalendar() {
  const { websiteId } = useParams();
  const [posts, setPosts] = useRecoilState(
    postsSummariesState(websiteId as string)
  );
  const [value, setValue] = useState<Value>(new Date());

  // Function to check if a date has an event
  const getEventsForDate = (date: Date) => {
    return posts.filter(
      (post) =>
        new Date(post.scheduleDate).toDateString() === date.toDateString()
    );
  };

  const combineDateAndTime = (dateObj: Date, timeObj: Date) => {
    console.log(timeObj);

    // Extract the date parts (year, month, day) from the first object
    const year = dateObj.getFullYear();
    const month = dateObj.getMonth(); // Month is zero-based
    const day = dateObj.getDate();

    const test = new Date(timeObj);
    // Extract the time parts (hours, minutes, seconds, milliseconds) from the second object
    const hours = test.getHours();
    const minutes = test.getMinutes();
    const seconds = test.getSeconds();

    // Create a new Date object with the date from dateObj and the time from timeObj
    return new Date(year, month, day, hours, minutes, seconds);
  };

  // Function to handle event drop
  const handleEventDrop = (post: PostEntity, newDate: Date) => {
    // Logic to update the post's scheduleDate to the new date

    postService.updatePost({ ...post, scheduleDate: newDate }).then(() =>
      setPosts((oldPosts) =>
        oldPosts.map((p) =>
          p.id === post.id
            ? {
                ...p,
                scheduleDate: combineDateAndTime(newDate, p.scheduleDate),
              }
            : p
        )
      )
    );
    // Here, you should update the state or make an API call to persist the change
    console.log("Dropped post:", post, "on date:", newDate);
    // updateRecoilState(updatedPosts); // Assuming you'll update the Recoil state here
  };

  const tileContent: TileContentFunc = useCallback(
    ({ date, view }) => {
      const today = new Date();

      if (view === "month") {
        const events = getEventsForDate(date);

        if (events.length > 0) {
          return (
            <>
              {events.map((post) => (
                <DraggablePost
                  key={post.id}
                  post={{ ...post, scheduleDate: new Date(post.scheduleDate) }}
                />
              ))}
            </>
          );
        }
      }

      // Check if the date is in the future or today
      if (date >= today || date.toDateString() === today.toDateString()) {
        return (
          <CreateDocument>
            <span>צור מסמך חדש</span>
          </CreateDocument>
        );
      }

      return null;
    },
    [posts]
  );

  const tileClassName = ({ date }: { date: Date }) => {
    const today = new Date();
    if (date < today && date.toDateString() !== today.toDateString()) {
      return "past-date";
    }
    return "";
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <CalendarStyled
        onChange={(val) => setValue(val)}
        value={value}
        tileContent={(params) => (
          <DroppableTile date={params.date} onDropEvent={handleEventDrop}>
            {tileContent(params)}
          </DroppableTile>
        )}
        tileClassName={tileClassName}
        locale="he"
      />
    </DndProvider>
  );
}

export default MyCalendar;
